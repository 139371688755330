<template>
    <div>

        <list :fs="brandFields" api="brands" editLink="/admin/brands" addLink="/admin/brands/new"></list>
    </div>
</template>

<script>
import List from "@/components/List";
import { brandFields } from "@/config";

export default {
    data() {
        return {
            brandFields: brandFields
        };
    },
    components: {
        List
    }
};
</script>